import React, { ReactElement, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { Loader } from '@app/components'
import { UserRole } from '@app/data'
import { useUserRole } from '@app/modules/settings/data/useUserRole'

import { authStore } from '../store/authStore'
import { IncorrectRole } from './IncorrectRole'

type Props = {
  children: React.ReactNode | React.ReactNode[] | null
}

export const RoleProvider = ({ children }: Props): ReactElement | null => {
  const { roles } = authStore.useStore((store) => store.userInfo)
  const { addUserRole, pending } = useUserRole()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    const addRole = async () => {
      const success = await addUserRole(UserRole.TEACHER)

      if (success?.data?.addUserRole) {
        await keycloak.updateToken(3600)
      }
    }

    if (
      roles &&
      !roles.includes(UserRole.TEACHER.toLocaleLowerCase()) &&
      !roles.includes(UserRole.STUDENT.toLocaleLowerCase())
    ) {
      addRole()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles?.length])

  if (pending) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (roles && roles.length > 0 && !roles.includes(UserRole.TEACHER.toLocaleLowerCase())) {
    return <IncorrectRole />
  }

  return <>{children}</>
}
