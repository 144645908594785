export const colors = {
  brand: {
    25: '#F6F6FE',
    50: '#D4CEFD',
    100: '#C6BDFC',
    200: '#A99CFB',
    300: '#9B8CFA',
    400: '#8D7BF9',
    500: '#705AF8',
    600: '#3A48C7',
    700: '#2F399F',
    800: '#232B78',
    900: '#171D50',
    1000: '#0C0E28'
  },
  positive: {
    25: '#F5FFFA',
    50: '#ECFCF4',
    100: '#E4FBEE',
    200: '#A7F1CA',
    300: '#79E2AA',
    400: '#50CE8B',
    500: '#18B461',
    600: '#079D4D',
    700: '#05773A',
    800: '#085D30',
    900: '#074D28',
    1000: '#06371D'
  },
  warning: {
    25: '#FFFCF5',
    50: '#FDF8ED',
    100: '#FAEFDB',
    200: '#FFDD99',
    300: '#FFC95C',
    400: '#FFB420',
    500: '#FFAD0A',
    600: '#F27202',
    700: '#B04508',
    800: '#963B0D',
    900: '#832B0B',
    1000: '#491A08'
  },
  negative: {
    25: '#FFF5F5',
    50: '#FFECEB',
    100: '#FFE3E0',
    200: '#FFD9D6',
    300: '#FFA099',
    400: '#FF675C',
    500: '#F7483B',
    600: '#D72A1D',
    700: '#B42318',
    800: '#96160D',
    900: '#71110A',
    1000: '#370906'
  },
  grayTrue: {
    25: '#FCFCFC',
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EBEBEB',
    300: '#E0E0E0',
    400: '#CCCCCC',
    500: '#999999',
    600: '#666666',
    700: '#525252',
    800: '#333333',
    900: '#1E1E1E',
    1000: '#0A0A0A'
  },
  grayNeutral: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F3F4F6',
    150: '#E8EAEE',
    200: '#E5E7EB',
    300: '#D2D6DB',
    400: '#9DA4AE',
    500: '#6C737F',
    600: '#4D5761',
    700: '#384250',
    800: '#1F2A37',
    900: '#111927',
    1000: '#0D121C'
  },
  grayWarm: {
    25: '#FDFDFC',
    50: '#FAFAF9',
    100: '#F5F5F4',
    200: '#E7E5E4',
    300: '#D7D3D0',
    400: '#A9A29D',
    500: '#79716B',
    600: '#57534E',
    700: '#44403C',
    800: '#292524',
    900: '#1C1917',
    1000: '#171412'
  },
  grayBlue: {
    25: '#FCFCFD',
    50: '#F8F9FC',
    100: '#EAECF5',
    200: '#D5D9EB',
    300: '#B3B8DB',
    400: '#717BBC',
    500: '#4E5BA6',
    600: '#3E4784',
    700: '#363F72',
    800: '#293056',
    900: '#101323',
    1000: '#0D0F1C'
  },
  greenLight: {
    25: '#FAFEF5',
    50: '#F3FEE7',
    100: '#E3FBCC',
    200: '#D0F8AB',
    300: '#A6EF67',
    400: '#85E13A',
    500: '#66C61C',
    600: '#4CA30D',
    700: '#3B7C0F',
    800: '#326212',
    900: '#2B5314',
    1000: '#15290A'
  },
  green: {
    25: '#F6FEF9',
    50: '#EDFCF2',
    100: '#D3F8DF',
    200: '#AAF0C4',
    300: '#73E2A3',
    400: '#3CCB7F',
    500: '#16B364',
    600: '#099250',
    700: '#087443',
    800: '#095C37',
    900: '#084C2E',
    1000: '#052E1C'
  },
  blueLight: {
    25: '#F5FBFF',
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#B9E6FE',
    300: '#7CD4FD',
    400: '#36BFFA',
    500: '#0BA5EC',
    600: '#0086C9',
    700: '#026AA2',
    800: '#065986',
    900: '#0B4A6F',
    1000: '#062C41'
  },
  blue: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
    1000: '#102A56'
  },
  blueDark: {
    25: '#F5F8FF',
    50: '#EFF4FF',
    100: '#D1E0FF',
    200: '#B2CCFF',
    300: '#84ADFF',
    400: '#528BFF',
    500: '#2970FF',
    600: '#155EEF',
    700: '#004EEB',
    800: '#0040C1',
    900: '#00359E',
    1000: '#002266'
  },
  indigo: {
    25: '#F4F8FF',
    50: '#EEF4FF',
    100: '#E0EAFF',
    200: '#C7D7FE',
    300: '#A4BCFD',
    400: '#8098F9',
    500: '#6172F3',
    600: '#444CE7',
    700: '#3538CD',
    800: '#2D31A6',
    900: '#2D3282',
    1000: '#1F235B'
  },
  violet: {
    25: '#FBFAFF',
    50: '#F5F3FF',
    100: '#ECE9FE',
    200: '#DDD6FE',
    300: '#C3B5FD',
    400: '#A48AFB',
    500: '#875BF7',
    600: '#7839EE',
    700: '#6927DA',
    800: '#5720B7',
    900: '#491C96',
    1000: '#2E125E'
  },
  purple: {
    25: '#FAF9FF',
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#BDB4FE',
    400: '#9B8AFB',
    500: '#7A5AF8',
    600: '#6938EF',
    700: '#5925DC',
    800: '#4A1FB8',
    900: '#3E1C96',
    1000: '#27115F'
  },
  fuchsia: {
    25: '#FEFAFF',
    50: '#FDF4FF',
    100: '#FBE8FF',
    200: '#F6D0FE',
    300: '#EEAAFD',
    400: '#E478FA',
    500: '#D444F1',
    600: '#BA24D5',
    700: '#9F1AB1',
    800: '#821890',
    900: '#6F1877',
    1000: '#47104C'
  },
  pink: {
    25: '#FDF6FB',
    50: '#FDF2FA',
    100: '#FCE7F6',
    200: '#FCCEEE',
    300: '#FAA7E0',
    400: '#F670C7',
    500: '#EE46BC',
    600: '#DD2590',
    700: '#C11574',
    800: '#9E165F',
    900: '#851651',
    1000: '#4E0D30'
  },
  rose: {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31B54',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E',
    1000: '#510B24'
  },
  orange: {
    25: '#FFF9F4',
    50: '#FFF5ED',
    100: '#FFEAD5',
    200: '#FDDCAA',
    300: '#FEB172',
    400: '#FD8439',
    500: '#FB6413',
    600: '#EC4909',
    700: '#C3320A',
    800: '#9B2910',
    900: '#7D2410',
    1000: '#511C10'
  },
  darkOrange: {
    25: '#FFF9F5',
    50: '#FFF4ED',
    100: '#FFE6D5',
    200: '#FFD6AE',
    300: '#FF9C66',
    400: '#FF692E',
    500: '#FF4405',
    600: '#E62E05',
    700: '#BC1B06',
    800: '#97180C',
    900: '#771A0D',
    1000: '#57130A'
  },

  white0: '#FFF',
  black0: '#000',

  primary500: '#FF8B1F',
  primary400: '#FF8B39',
  primary300: '#FFAC71',
  primary200: '#FFCDAA',
  primary100: '#FFE8D2',
  primary50: '#FFF4E9',
  neutral1000: '#313131',
  neutral900: '#252525',
  neutral800: '#414141',
  neutral700: '#5C5C5C',
  neutral600: '#777777',
  neutral500: '#9F9F9F',
  neutral400: '#ADADAD',
  neutral300: '#C9C9C9',
  neutral200: '#DEDEDE',
  neutral100: '#F0F0F0',
  neutral50: '#FAFAFA',
  danger500: '#E91C00',
  danger400: '#FF4229',
  danger300: '#FF7866',
  danger200: '#FFAEA3',
  danger100: '#FFE4E0',
  danger50: '#FFF0ED',
  warning500: '#FFBF00',
  warning400: '#FFC344',
  warning300: '#FFE9BC',
  warning200: '#FFD3CC',
  warning100: '#FFF4CD',
  warning50: '#FFF5DE'
}
