import { initReactI18next, useTranslation as i18UseTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import i18n, { TOptionsBase } from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import csZodTranslation from 'zod-i18n-map/locales/cs/zod.json'

dayjs.extend(durationPlugin)
dayjs.extend(relativeTimePlugin)

import * as CZ_TRANSLATIONS from './cz'
import * as EN_TRANSLATIONS from './en'

import 'dayjs/locale/cs'
import 'dayjs/locale/en'

export enum SupportedAppLanguage {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH'
}

export type ResourceLangType = 'common' | 'error' | 'classroom' | 'settings' | 'games' | 'performance'

export type AppLanguages = 'cs-CZ' | 'en-US'

export const appLanguageToCode: Record<SupportedAppLanguage, AppLanguages> = {
  [SupportedAppLanguage.CZECH]: 'cs-CZ',
  [SupportedAppLanguage.ENGLISH]: 'en-US'
}

export const languageCodeToSupportedLanguage: Record<AppLanguages, SupportedAppLanguage> = {
  'cs-CZ': SupportedAppLanguage.CZECH,
  'en-US': SupportedAppLanguage.ENGLISH
}

type TOptions = TOptionsBase & { [key: string]: string | number | undefined }

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init(
    {
      compatibilityJSON: 'v3',
      lng: 'cs-CZ',
      simplifyPluralSuffix: false,
      pluralSeparator: '_',
      resources: {
        'en-US': EN_TRANSLATIONS,
        'cs-CZ': { ...CZ_TRANSLATIONS, zod: csZodTranslation }
      },
      defaultNS: 'common',
      debug: import.meta.env.DEV, //Just for testing missing key output,
      returnNull: false,
      interpolation: {
        escapeValue: false
      }
    },
    () => {
      const storedAppLang = localStorage.getItem('lng')
      const appLang = storedAppLang ? appLanguageToCode[storedAppLang as SupportedAppLanguage] : 'cs-CZ'

      i18n.changeLanguage(appLang).catch((err) => console.info('[i18n] changeLanguage error ', err))

      dayjs.locale(appLang)

      return true
    }
  )
  .catch((error) => console.error('[i18n] init error', error))

z.setErrorMap(zodI18nMap)

export default i18n

export const useTranslation = (ns?: ResourceLangType | ResourceLangType[]) => {
  const { t: i18t } = i18UseTranslation()

  const t = (i18nKey: string, options?: TOptions): string => {
    return i18t<string>(i18nKey, { ...options, ns })
  }

  return { t }
}
