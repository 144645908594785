import { forwardRef } from 'react'
import classnames from 'classnames'

import { Avatar, AvatarProps } from '../Avatar'
import { Tooltip } from '../Tooltip'

type SizeType = 'small' | 'normal' | 'big'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'size' | 'onClick'> & {
  avatars: AvatarProps[]
  size?: SizeType
  onAddClick?: () => void
  numberOfUsers?: number
  tooltipLabel?: string
  showAvatarTooltip?: boolean
  disabled?: boolean
}

const groupSizeToAvatarSize: Record<SizeType, AvatarProps['size']> = {
  small: 'xs',
  normal: 's',
  big: 'm'
}

const sizeToPosition: Record<SizeType, number> = {
  small: 20,
  normal: 25,
  big: 30
}

const sizeToSizeStyle: Record<SizeType, string> = {
  small: 'w-[28px] h-[28px]',
  normal: 'w-[34px] h-[34px]',
  big: 'w-[42px] h-[42px]'
}

const sizeToFontSize: Record<SizeType, string> = {
  small: 'text-xs',
  normal: 'text-[13px] leading-4',
  big: 'text-base '
}

const sizeToHeight: Record<SizeType, string> = {
  small: 'h-7',
  normal: 'h-9',
  big: 'h-11'
}

/**
 * @group Components
 * @category Component
 */
export const AvatarGroup = forwardRef(
  (
    {
      className,
      onAddClick,
      size = 'normal',
      numberOfUsers = 10,
      avatars = [],
      tooltipLabel,
      disabled,
      showAvatarTooltip,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const displayedAvatars = avatars.slice(0, numberOfUsers)
    const hiddenAvatars = avatars.slice(numberOfUsers)
    const avatarSize = groupSizeToAvatarSize[size]
    const buttonSize = sizeToSizeStyle[size]
    const fontSize = sizeToFontSize[size]
    const height = sizeToHeight[size]

    return (
      <div
        ref={ref}
        className={classnames('w-fit', height)}
        style={{ width: (displayedAvatars.length + (hiddenAvatars.length > 0 ? 3 : 2)) * sizeToPosition[size] }}
        {...restProps}
      >
        <div className={classnames('relative flex flex-row', className)}>
          {displayedAvatars.map((avatar, index) => {
            return (
              <div
                key={index}
                className="absolute rounded-full border-2 border-primary-600"
                style={{ left: index * sizeToPosition[size] }}
              >
                <Avatar {...avatar} ref={undefined} size={avatarSize} showTooltip={showAvatarTooltip} />
              </div>
            )
          })}
          {hiddenAvatars.length > 0 && (
            <div
              className={classnames(
                'absolute flex items-center justify-center rounded-full border-2 border-primary-600 bg-primary-700 p-3',
                buttonSize
              )}
              style={{ left: displayedAvatars.length * sizeToPosition[size] }}
            >
              <p className={classnames('rounded-full text-center text-neutral-500', fontSize)}>
                +{hiddenAvatars.length}
              </p>
            </div>
          )}
          {onAddClick && (
            <div className="flex w-full items-center justify-end">
              <Tooltip dark label={tooltipLabel || ''} side="top" sideOffset={5} disabled={!tooltipLabel || disabled}>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={onAddClick}
                  className={classnames(
                    'border-grayNeutral-300 peer right-0 flex items-center justify-center rounded-full border-[1px] border-dashed bg-white text-neutral-300',
                    !disabled &&
                      'active:border-2 active:border-solid active:border-primary-500 active:text-primary-300',
                    'hover:bg-primary-600 hover:text-primary-200',
                    'disabled:bg-primary-800 disabled:text-primary-600',
                    buttonSize
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="peer-hover:text-grayTrue-700"
                  >
                    <path
                      d="M8.00065 3.33334V12.6667M3.33398 8.00001H12.6673"
                      stroke="currentColor"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <Plus  */}
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    )
  }
)
