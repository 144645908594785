import { ReactNode, useEffect, useState } from 'react'
import { animated, config, useTransition } from '@react-spring/web'
import * as Dialog from '@radix-ui/react-dialog'
import { XClose } from '@untitled-ui/icons-react'

type Props<T> = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  data?: T
}

export const RightPart = function <T extends ReactNode>({ isOpen, setIsOpen, data = <></> }: Props<T>) {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!isOpen) {
      timer = setTimeout(() => {
        setOpen(false)
      }, 1000)
    } else {
      setOpen(true)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isOpen])

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, x: window.innerWidth },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: window.innerWidth },
    config: config.default
  })

  return (
    <Dialog.Root open={open} onOpenChange={setIsOpen}>
      {transitions((styles, item) => {
        if (!item) return null

        return (
          <Dialog.Portal>
            <Dialog.Overlay
              className="fixed bottom-0 left-0 right-0 top-0 place-items-center overflow-y-auto bg-black/30"
              asChild
              forceMount
            >
              <animated.div
                style={{
                  opacity: styles.opacity
                }}
              />
            </Dialog.Overlay>
            <Dialog.Content forceMount asChild className="absolute right-0 top-0 h-[100vh] w-[50%] bg-white">
              <animated.div
                style={{
                  opacity: styles.opacity,
                  transform: styles.x.to((x) => `translateX(${x}px)`)
                }}
              >
                <>
                  <header className="flex h-[60px] w-full items-center justify-between border-b-[1px] border-neutral-200 p-3">
                    <XClose onClick={() => setIsOpen(false)} className="text-neutral-500 hover:cursor-pointer" />
                  </header>

                  {data && <div className="p-3">{data}</div>}
                </>
              </animated.div>
            </Dialog.Content>
          </Dialog.Portal>
        )
      })}
    </Dialog.Root>
  )
}
