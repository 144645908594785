import { forwardRef, ReactElement, ReactNode } from 'react'
import React from 'react'
import classnames from 'classnames'
import * as Select from '@radix-ui/react-select'
import { ArrowUp, Check, ChevronDown, User01 } from '@untitled-ui/icons-react'

import { colors } from '@foxino/configs/tailwind/colors'

type VariantType = 'outline'

const disabledStyles = 'bg-primary-800 text-primary-600 border-primary-600'
const validVariantToStyles: Record<VariantType, string> = {
  outline:
    'focus:border-2 bg-primary-900 focus:border-primary-400 border-primary-600 border-2 placeholder:text-neutral-100 shadow-xs hover:border-primary-400'
}
const errorVariantToStyles: Record<VariantType, string> = {
  outline: 'border-error-500 border-2 placeholder:text-neutral-100 focus-within:border-error-500'
}

type Props = Select.SelectProps & {
  disabled?: boolean
  variant?: VariantType
  error?: boolean
  label?: string
  description?: string
  placeholder?: string
  errorText?: string
  className?: string
  options: SelectItemProps[]
  showDot?: boolean
  showImage?: boolean
  hideSelectIcon?: boolean
  viewportClassName?: React.ComponentProps<'div'>['className']
  valueComponent?: ReactElement
  triggerClassName?: React.ComponentProps<'div'>['className']
}

/**
 * @group Components
 * @category Component
 */
export const InputDropdown = forwardRef(
  (
    {
      className,
      disabled,
      description,
      placeholder,
      error,
      errorText,
      label,
      options = [],
      variant = 'outline',
      hideSelectIcon,
      showImage,
      showDot,
      value,
      viewportClassName,
      triggerClassName,
      onValueChange,
      valueComponent,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorText || error
    const validVariantStyle = validVariantToStyles[variant]
    const errorVariantStyle = errorVariantToStyles[variant]

    return (
      <div className={classnames('flex w-full min-w-[40px] flex-col gap-2', className)} ref={ref}>
        {label ? <label className="text-sm text-primary-200">{label}</label> : null}
        <Select.Root onValueChange={onValueChange} {...restProps} value={value}>
          <Select.Trigger asChild disabled={disabled}>
            <div
              className={classnames(
                'inline-flex h-12 items-center justify-between gap-[5px] rounded-lg px-2 text-sm text-primary-200 outline-none data-[placeholder]:text-primary-600',
                'focus-within:ring-0',
                disabled ? disabledStyles : hasError ? errorVariantStyle : validVariantStyle,
                !disabled && 'cursor-pointer',
                triggerClassName
              )}
            >
              <Select.Value placeholder={placeholder}>{valueComponent ? valueComponent : value}</Select.Value>
              {!hideSelectIcon && (
                <Select.Icon className="text-primary-200">
                  <ChevronDown />
                </Select.Icon>
              )}
            </div>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content className="z-50 !max-h-[300px] overflow-hidden rounded-lg border-2 border-primary-600 bg-primary-800 shadow-m">
              <div className="overflow-auto">
                <Select.ScrollUpButton className="flex h-[25px] cursor-default items-center justify-center bg-primary-800 text-white">
                  <ArrowUp />
                </Select.ScrollUpButton>
                <Select.Viewport className={(classnames(viewportClassName), 'h-max-[250px]')}>
                  <Select.Group>
                    {options.map((option, index) => {
                      return (
                        <SelectItem key={index} {...option} showImage={showImage} showDot={showDot}>
                          {option.textValue || option.value}
                        </SelectItem>
                      )
                    })}
                  </Select.Group>
                </Select.Viewport>
              </div>
            </Select.Content>
          </Select.Portal>
        </Select.Root>

        {/* Error text */}
        {errorText && !disabled ? (
          <p className="text-sm text-error-500">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-primary-200">{description}</p>
        ) : null}
      </div>
    )
  }
)

export type SelectItemProps = Select.SelectItemProps & {
  url?: string | ReactNode
  showImage?: boolean
  showDot?: boolean
}

const SelectItem = React.forwardRef(
  (
    { children, showDot, url, showImage, ...props }: SelectItemProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
  ) => {
    const Image = typeof url === 'object' ? (url as unknown as React.FC) : 'div'

    return (
      <Select.Item
        className={classnames(
          'relative flex h-12 cursor-pointer select-none items-center gap-2 rounded-lg px-[14px] text-[14px] text-primary-100 hover:bg-primary-700 data-[disabled]:pointer-events-none data-[highlighted]:bg-primary-700 data-[disabled]:text-primary-600 data-[highlighted]:outline-none'
        )}
        {...props}
        ref={forwardedRef}
      >
        {showDot && (
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="4" fill={colors.positive[500]} />
          </svg>
        )}
        {showImage && (
          <>
            {typeof url === 'string' ? (
              <div className="h-6 w-6 overflow-hidden rounded-full">
                <img src={url} alt="picture" />
              </div>
            ) : typeof url === 'object' ? (
              <Image />
            ) : (
              <User01 className="text-primary-100" />
            )}
          </>
        )}

        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute right-2 inline-flex w-[25px] items-center justify-center text-primary-100">
          <Check />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)
