import DatePicker, { DatePickerProps, registerLocale } from 'react-datepicker'
import classNames from 'classnames'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-US'
import { ChevronDown } from '@untitled-ui/icons-react'

import { useVisibleState } from '@foxino/components-common'

import 'react-datepicker/dist/react-datepicker.css'

registerLocale('cs-CZ', cs)
registerLocale('en-US', en)

type Props = DatePickerProps & {
  datePickerClassName?: string
}

/**
 * @group Components
 * @category Props
 */
export const DatePickerInput = ({ className, showIcon, datePickerClassName, disabled, ...restProps }: Props) => {
  const [calendarVisible, showCalendar, hideCalendar] = useVisibleState()

  const handleOnShowClick = () => {
    if (disabled) return
    showCalendar()
  }

  return (
    <div
      onClick={handleOnShowClick}
      className={classNames(
        'flex flex-row items-center justify-between gap-1 rounded-lg border-[1px] border-primary-600 bg-primary-900 py-2 pr-1 text-sm',
        showIcon ? 'pl-0' : 'pl-2',
        className
      )}
    >
      <DatePicker
        {...restProps}
        className={classNames('w-[calc(107%)]', datePickerClassName)}
        open={calendarVisible}
        onInputClick={handleOnShowClick}
        onClickOutside={hideCalendar}
        disabled={disabled}
        showIcon={showIcon}
      />
      <ChevronDown
        className={classNames('text-neutral-500', disabled && 'text-primary-600')}
        onClick={handleOnShowClick}
      />
    </div>
  )
}
