import classnames from 'classnames'

import { Typography, TypographyProps, WeightTextType } from '.'

export type LabelSizeType = 'm' | 's' | 'l'

const sizeToStyle: Record<LabelSizeType, React.ComponentProps<'p'>['className']> = {
  l: 'text-sm',
  m: 'text-xs',
  s: 'text-[10px] leading-[16px]'
}

const weightToStyle: Record<WeightTextType, React.ComponentProps<'p'>['className']> = {
  bold: 'font-semibold',
  semibold: 'font-medium',
  medium: 'font-normal',
  regular: 'font-light'
}

export type LabelProps = Omit<TypographyProps, 'variant'> & {
  size?: LabelSizeType
  label?: string
}

/**
 * @group Components
 * @category Atoms
 */
export const Label = ({
  className,
  weight = 'regular',
  size = 'm',
  Component = 'p',
  children,
  label,
  ...props
}: LabelProps) => {
  const sizeStyle = sizeToStyle[size]
  const weightStyle = weightToStyle[weight]

  return (
    <Typography
      className={classnames('text-light-black', sizeStyle, weightStyle, className)}
      weight={weight}
      Component={Component}
      {...props}
    >
      {label || children}
    </Typography>
  )
}
