import { forwardRef, ReactElement, ReactNode } from 'react'
import React from 'react'
import classNames from 'classnames'
import * as AccordionComponent from '@radix-ui/react-accordion'
import { ChevronDown } from '@untitled-ui/icons-react'

import { IconType } from '..'

import './Accordion.css'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'label'> & {
  children: ReactNode
  label: ReactNode
  icon?: ReactElement<IconType> | ReactElement<any>
}

/**
 * @group Components
 * @category Component
 */
export const Accordion = forwardRef(({ className, label, children, icon }: Props, ref: React.Ref<HTMLDivElement>) => {
  return (
    <AccordionComponent.Root
      className={classNames('w-full', className)}
      type="single"
      defaultValue="item-1"
      collapsible
      ref={ref}
    >
      <AccordionComponent.Item value="item-1">
        {/* Trigger */}
        <AccordionComponent.Header className="flex px-3">
          <AccordionComponent.Trigger asChild>
            <div
              className={classNames(
                'group flex h-[45px] flex-1 cursor-default flex-wrap items-center justify-between leading-none outline-none',
                className
              )}
            >
              <div className="flex flex-row items-center gap-2 text-sm">
                {icon && React.cloneElement(icon, { className: classNames(icon.props.className) })}
                {label}
              </div>
              <ChevronDown
                className="cursor-pointer rounded-md text-primary-100 transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] hover:bg-primary-600 group-data-[state=open]:rotate-180"
                aria-hidden
              />
            </div>
          </AccordionComponent.Trigger>
        </AccordionComponent.Header>

        {/* Content */}
        <AccordionComponent.Content
          className={classNames(
            'overflow-hidden data-[state=closed]:animate-[accordion-out_100ms_ease-in-out] data-[state=open]:animate-[accordion-in_100ms_ease-in-out]',
            className
          )}
        >
          <div>{children}</div>
        </AccordionComponent.Content>
      </AccordionComponent.Item>
    </AccordionComponent.Root>
  )
})
