import ReactDOM from 'react-dom/client'

import App from './App.js'

import 'react-toastify/dist/ReactToastify.min.css'
import '../tailwind.css'
import './App.css'

if (import.meta.env.MODE === 'development') {
  const { setupMockWorker } = await import('./app/mock/worker.mock.ts')
  setupMockWorker().start({ onUnhandledRequest: 'bypass' })
}

// Main styles to work with tailwind
ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
