import React, { ReactElement, SVGProps } from 'react'

export const ItalyFlagRoundIcon = React.forwardRef(
  ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>): ReactElement => {
    return (
      <svg
        width={width}
        height={height}
        ref={ref}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_585_29121)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#F0F0F0"
          />
          <path
            d="M23.9999 11.9991C23.9999 6.83951 20.7434 2.441 16.1738 0.745483V23.2527C20.7434 21.5572 23.9999 17.1587 23.9999 11.9991Z"
            fill="#D80027"
          />
          <path
            d="M-0.000244141 11.9997C-0.000244141 17.1593 3.25626 21.5578 7.82587 23.2533V0.746094C3.25626 2.44161 -0.000244141 6.84013 -0.000244141 11.9997Z"
            fill="#6DA544"
          />
        </g>
        <defs>
          <clipPath id="clip0_585_29121">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
)
