import axios from 'axios'

import { authStore } from '../store/authStore'

export const addUnauthorizedHandlerToAxios = (
  getAccessToken: () => Promise<string | undefined>,
  logout: () => Promise<void>
): void => {
  axios.interceptors.request.use(
    async (config) => {
      if (authStore.isAuthorized()) {
        const token = await getAccessToken()

        // @ts-ignore
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      }

      return config
    },
    async (error) => {
      await Promise.reject(error)
    }
  )

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (axios.isAxiosError(error) && error.code === '401') {
        await logout()
      }
      return Promise.reject(error)
    }
  )
}
