import * as Sentry from '@sentry/react'

export const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://cc3b8f02b1dd4713eaaa7b1139de9a90@sentry.dev.foxino.com/4',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.foxino\.com\/graphql/]
      }),
      new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing()
    ],
    // Performance Monitoring
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    enabled: import.meta.env.DEV !== true,
    ignoreErrors: ['Access Denied', 'Network Error'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // To set a uniform sample rate
    // Set profiles_sample_rate to 1.0 to profile 100%
    // of sampled transactions.
    // We recommend adjusting this value in production
    profilesSampleRate: 1.0
  })
}
