import { useNavigate } from 'react-router-dom'
import * as Dialog from '@radix-ui/react-dialog'
import { XClose } from '@untitled-ui/icons-react'

import { Pages } from '@app/config/router/PagesEnum'
import { useGetSubjects } from '@app/modules/learningPlan/data/useGetSubjects'

import { createClassStore } from '../../store/createClassStore'
import { NewClassForm } from './components/NewClassForm'

export const NewClassDialog = () => {
  const navigate = useNavigate()
  const isOpen = createClassStore.useStore((state) => state.isDialogOpen)
  const { subjects } = useGetSubjects()

  const onClassCreated = (classId: string) => {
    createClassStore.setDialogOpen(false)
    navigate(Pages.CLASSROOM_DETAIL(classId))
  }

  const handleOnClose = () => {
    createClassStore.setDialogOpen(false)
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 bg-black opacity-50" />
        <Dialog.Content className="fixed left-[50%] top-[50%] z-10 flex max-h-[85vh] w-[90vw] max-w-[550px] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-primary-900 p-10 shadow-2xl">
          <NewClassForm onClassroomCreated={onClassCreated} subjects={subjects} />

          <Dialog.Close className="absolute right-4 top-4 text-primary-200 hover:opacity-40">
            <XClose />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
