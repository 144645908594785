import { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Loader } from '@app/components'
import { NewClassDialog } from '@app/modules/classroom/view/NewClassPage/NewClassDialog'
import { LogUtils } from '@app/utils/LogUtils'

import { RightPart } from '../RightPart/RightPart'
import { SideNavigation } from '../SideNavigation'

export const BasicLayout = () => {
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()

  useEffect(() => {
    LogUtils.logScreenView(location.pathname)
  }, [location])

  return (
    <Suspense
      fallback={
        <div className="text-pr flex h-screen w-screen items-center justify-center">
          <Loader />
        </div>
      }
    >
      <div className="flex h-screen w-full bg-primary-1000 text-white">
        <NewClassDialog />
        <SideNavigation />

        <div className="flex w-full flex-1 flex-col">
          <main className="h-full text-white">
            <Outlet />
          </main>
        </div>

        <RightPart isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </Suspense>
  )
}
