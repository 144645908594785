import classnames from 'classnames'

export type WeightTextType = 'bold' | 'semibold' | 'medium' | 'regular'

export type TypographyProps = {
  className?: string
  weight?: WeightTextType
  Component?: React.ElementType
} & React.HTMLAttributes<HTMLParagraphElement>

/**
 * @group Components
 * @category Atoms
 */
export const Typography = ({ className, weight = 'regular', Component = 'p', children, ...props }: TypographyProps) => {
  return (
    <Component className={classnames('text-light-black', className)} weight={weight} {...props}>
      {children}
    </Component>
  )
}
