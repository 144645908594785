import { forwardRef } from 'react'
import classnames from 'classnames'

type VariantType = 'outlined' | 'simple'

type Props = React.HTMLProps<HTMLTextAreaElement> & {
  disabled?: boolean
  error?: boolean
  label?: string
  description?: string
  errorText?: string
  variant?: VariantType
  textareaClassName?: string
}

const validClasses =
  'bg-primary-800 border-primary-600 placeholder:text-neutral-500 focus-within:border-primary-400 hover:border-primary-400'
const errorClasses = 'bg-primary-800 border-error-500 placeholder:text-neutral-500 focus-within:border-error-500'
const disabledClasses = 'bg-primary-800 text-primary-600 border-primary-600 placeholder:text-primary-600'

const variantToStyles: Record<VariantType, string> = {
  outlined: 'px-[14px] py-[10px] border-2 focus-within:border-2',
  simple: ''
}

/**
 * @group Components
 * @category Component
 */
export const TextArea = forwardRef(
  (
    {
      className,
      disabled,
      description,
      error,
      errorText,
      label,
      inputMode = 'text',
      variant = 'outlined',
      textareaClassName,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const hasError = !!errorText || error
    const variantStyle = variantToStyles[variant]

    return (
      <div className={classnames('flex w-full flex-col gap-2', className)}>
        {label ? <label className="text-sm text-primary-200">{label}</label> : null}
        <div
          className={classnames(
            'flex w-full flex-row items-center gap-1 rounded-lg text-sm',
            disabled ? disabledClasses : hasError ? errorClasses : validClasses,
            variantStyle,
            ''
          )}
        >
          <textarea
            ref={ref}
            inputMode={inputMode}
            disabled={disabled}
            rows={4}
            className={classnames(
              'flex-1 resize-none items-center rounded bg-primary-800 focus:outline-none',
              textareaClassName
            )}
            {...restProps}
          />
        </div>
        {errorText && !disabled ? (
          <p className="text-negative text-sm">{errorText}</p>
        ) : description ? (
          <p className="text-sm text-neutral-500">{description}</p>
        ) : null}
      </div>
    )
  }
)
